<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">套餐账单</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-date-range label="套餐服务时间" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-input label="用户手机号" v-model="pageParam.params.mobile" />
                <le-input label="套餐ID" v-model="pageParam.params.monthcardId" />
                <le-input label="套餐名称" v-model="pageParam.params.monthcardName" />
                <le-company-under-select label="商户" :multiple="true" :collapse="true" v-model="pageParam.params.companyId" placeholder="请选择（可输入搜索）"></le-company-under-select>
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
            </le-search-form>
            <le-pagview ref="financePackage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financePackageList">
                <el-table ref="financePackageList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="mobile" label="用户手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile?row.mobile:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userId" label="用户ID" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.userId?row.userId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="monthcardName" label="套餐名称" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.monthcardName?row.monthcardName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userMonthcardId" label="套餐ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userMonthcardId?row.userMonthcardId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="company" label="商户" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.company?row.company:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeName" label="项目" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeName?row.incomeName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="站点" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="term" label="当期/总期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.term || row.term===0?row.term:'-' }}/{{ row.totalTerm?row.totalTerm:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" label="订单总金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payAmount" label="实付金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="currentRecordAmount" label="本期入账金额" min-width="110">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.currentRecordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recordAmount" label="已入账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.recordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="完结状态" 
                        min-width="100"
                        column-key="status"
                        :filter-multiple='false'
                        :filters="statusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceStart" label="服务开始时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.serviceStart?row.serviceStart:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceEnd" label="服务结束时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.serviceEnd?row.serviceEnd:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" min-width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getFinancePackageInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.reconciliationList,
                    method: "post",
                    params: {
                        companyId: [],
                        incomeId: '',
                        mobile: '',
                        monthcardId: '',
                        monthcardName: '',
                        stationId: '',
                        startDate: '',
                        endDate: '',
                        status: '',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsStation: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",
                },//站点数据
                statusDic: [],//完结状态字典
            }
        },
        created () {
            this.$getDic('monthCardRecType').then(res=>{ this.statusDic = res; })
        },
        mounted () {
            
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: [],
                    incomeId: '',
                    mobile: '',
                    monthcardId: '',
                    monthcardName: '',
                    stationId: '',
                    startDate: '',
                    endDate: '',
                };
                this.$refs['financePackageList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financePackage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 查看收益账单详情
            getFinancePackageInfo (datas) {
                this.$router.push({
                    path: '/finance/finance-package-details',
                    query: {
                        id: datas.id
                    }
                })
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='status'){
                        if(value[key].length==0){
                            this.pageParam.params.status = ''
                        }else{
                            this.pageParam.params.status = value[key][0]
                        }
                    }
                }
                this.$refs['financePackage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 4,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 套餐账单',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>